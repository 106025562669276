<template>
  <v-card flat :class="['mx-auto', { 'is-auto': loop.isAuto }]" style="height: 100%">
    <v-card-title>
      <router-link :to="`/loops/${loop.searchId}/matches`" @click="$emit('click-info')" class="text-h5 job-title-header"
        ><span>
          {{ loop.jobTitle }}
          <small class="font-weight-light grey--text text--darken-2 location">- {{ jobLocation }}</small>
        </span>
      </router-link>

      <v-spacer></v-spacer>

      <v-menu bottom left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <app-icon name="dotsVertical" />
          </v-btn>
        </template>

        <v-list>
          <v-list-item :to="`/loops/${loop.searchId}`" @click="$emit('click-info')">
            <v-list-item-title>{{ $t("automations.info") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('click-edit', loop.searchId)">
            <v-list-item-title>{{ $t("automations.edit") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('click-export', loop)">
            <v-list-item-title>{{ $t("automations.export") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('click-duplicate', loop.searchId)">
            <v-list-item-title>{{ $t("automations.duplicate") }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="$emit('click-delete', loop.searchId)">
            <v-list-item-title>{{ $t("automations.delete") }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-text class="pb-0">
      <div class="row">
        <v-chip v-if="loop.isAuto" color="green" label class="white--text" small>{{ $t("automations.autoGenerated") }}</v-chip>
        <span v-else class="green--text font-weight-black">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">{{ $t("automations.active") }}</span>
            </template>
            {{ $t('automations.searching') }}
          </v-tooltip>
        </span>
        <template v-if="!loop.autoApply">
          <span><AppIcon name="circleSmall" style="font-size: 20px" /></span>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <span class="red--text font-weight-black" v-bind="attrs" v-on="on">
                {{ $t("automations.auto-apply-disabled") }}
              </span>
            </template>
            {{ $t('automations.auto-apply-disabled--tooltip') }}
          </v-tooltip>
        </template>
      </div>
      <v-list dense class="info-list">
        <template v-if="loading">
          <v-list-item v-for="n in 2" :key="n">
            <v-list-item-content>
              <div class="text-loader"></div>
            </v-list-item-content>
          </v-list-item>
        </template>

        <template v-else>
          <!-- Jobs found -->
          <v-list-item>
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="magnify" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.stats.jobsFoundTooltip') }}
            </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ loop.totalJobs || 0 }}</b>
                {{ $t("automations.stats.jobsFound") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Jobs matched -->
          <v-list-item>
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="link" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.stats.jobsMatchedTooltip') }}
            </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ loop.totalMatches || 0 }}</b>
                {{ $t("automations.stats.jobsMatched") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Matches with emails -->
          <v-list-item>
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="emailCheckOutline" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.stats.matchesWithEmailsTooltip') }}
            </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ loop.totalMatchesWithEmails || 0 }}</b>
                {{ $t("automations.stats.matchesWithEmails") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Matches with applications -->
          <v-list-item v-if="!onlyEmail">
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="applicationImport" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.stats.matchesWithApplicationsTooltip') }}
            </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ loop.totalMatchesWithApplications || 0 }}</b>
                {{ $t("automations.stats.matchesWithApplications") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Matches with pending questions -->
          <v-list-item v-if="loop.totalMatchesWithPendingQuestions" class="double-column">
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="commentQuestion" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.stats.questionsTooltip') }}
              </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                <b>{{ loop.totalMatchesWithPendingQuestions }}</b>
                {{ " " }}
                <span v-html="$t('automations.stats.questions')" />
                <v-btn icon x-small color="grey" :to="`/loops/${loop.searchId}/matches?filter=questions`">
                  <v-icon>open_in_new</v-icon>
                </v-btn>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <!-- Loop has no CV -->
          <v-list-item v-if="!loop.hasCV" class="double-column">
            <v-list-item-icon>
              <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <app-icon left name="closeBoxOutline" v-bind="attrs" v-on="on" />
              </template>
              {{ $t('automations.noCVTooltip') }}
            </v-tooltip>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>
                {{ $t("automations.noCV") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loop: {
      type: Object,
      default: () => ({}), // needs function
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    jobLocation() {
      if (this.loop.onlyRemoteJobs && this.loop.jobLocation === "Remote") {
        return this.$t("loopPreview.remote-anywhere");
      } else if (this.loop.onlyRemoteJobs) {
        return this.$t("loopPreview.remote-jobLocation", { 0: this.loop.jobLocation });
      } else {
        return this.loop.jobLocation;
      }
    },

    onlyEmail() {
      return this.$store.getters.configs?.onlyEmail;
    },
  },
};
</script>

<style>
.job-title-header > span,
.job-title-header {
  font-weight: dark;
  text-decoration: none;
  color: black;
}

.job-title-header:hover > span,
.job-title-header:hover {
  color: #4992f0 !important;
}
.info-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  padding: 20px 0 40px;
}

.double-column {
  grid-column: 2 span;
}

@media screen and (max-width: 600px) {
  .double-column {
    grid-column: 1 span;
  }

  .location {
    white-space: nowrap;
  }
}
</style>
