<template>
  <v-dialog
    :value="show"
    @input="$emit('update:show', $event)"
    max-width="500"
  >
    <v-card>
      <v-card-title>
        <div class="text-body-1">
          {{ $t('automations.duplicateLoopConfirmationDialog') }}
        </div>
      </v-card-title>
      <v-card-actions class="justify-center">
        <v-btn elevation="0" @click="$emit('update:show', false)"> {{ $t('no') }} </v-btn>
        <v-btn color="primary" @click="duplicateLoop">{{ $t('yes') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ["show", "searchIdToDuplicate"],
  data() {
    return {};
  },
  methods: {
    duplicateLoop() {
      this.$router.push(`/loops/${this.searchIdToDuplicate}/duplicate`);
    },
  },
};
</script>

<style></style>
