<template>
  <v-row style="width: 100%">
    <app-header />
    <div class="col-12">
      <div>
        <!-- Page title -->
        <div class="mx-2">
          <div class="row d-flex justify-md-space-between justify-sm-start flex-sm-column flex-md-row">
            <div class="my-3">
              <h4 class="text-h1">
                <span>{{ $t("automations.title.subtitle") }}</span>
                <span>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon color="black" class="pl-1" v-bind="attrs" v-on="on">help_outline</v-icon>
                    </template>
                    <span>{{ $t('automations.title.tooltip') }}</span>
                  </v-tooltip>
                </span>
              </h4>
            </div>
            <div class="my-3">
              <div v-if="!isLoading && areThereLoops && withoutApplications">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ $t("automations.toggle.label") }}</span>
                  </template>
                  <span>{{ $t('automations.toggle.tooltip') }}</span>
                </v-tooltip>

                <v-switch
                  class="pl-1 mt-0 pt-0"
                  style="display: inline-block"
                  dense
                  height="0"
                  hide-details
                  v-model="enableEmails"
                  @change="changeEmails"
                ></v-switch>
              </div>
            </div>
          </div>
        </div>

        <!-- Loading -->
        <div v-if="isLoading">
          <app-loader />
        </div>

        <div v-if="!isLoading && areThereLoops" class="ma-2">
          <div class="row" v-if="lastScannedText">
            <div class="text-subtitle-2 pa-3">
              {{ $t("automations.lastScanned") }} {{ " " }}
              {{ lastScannedText }}
            </div>
          </div>
          <div class="loops-wrapper">
            <div v-for="loop in loops" :key="loop.searchId" class="col-12 loop-block-parent">
              <LoopListItemPresentation
                :loop="loop"
                :loading="isLoading"
                @click-info="handleLoopClick"
                @click-edit="editLoop"
                @click-duplicate="promptToDuplicateLoop"
                @click-delete="promptToDeleteLoop"
                @click-export="$refs.exportJobsDialog.open($event)"
              />
            </div>
          </div>
        </div>

        <!-- No loops -->
        <div v-if="!isLoading && !areThereLoops">
          <center>
            <div>
              <div class="row">
                <div class="col-12">
                  <center>
                    <v-btn color="primary" @click="newloop">
                      {{ $t("automations.new") }}
                    </v-btn>
                  </center>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  {{ $t("automations.noLoops") }}
                </div>
              </div>
            </div>
            <app-loader v-if="isLoading" />
          </center>
        </div>
      </div>
    </div>

    <v-dialog v-model="showLoopDeletionDialog" max-width="500">
      <v-card class="pa-5">
        <!-- <div class="row">
          <div class="col-md-11"> -->
        <center>
          <h4>{{ $t("automations.deleteDialog.question") }}</h4>
        </center>
        <!-- </div>
          <div class="col-md-1 pointer" @click="showLoopDeletionDialog=false">
            <h4><i class="tim-icons icon-simple-remove"></i></h4>
          </div>
        </div> -->
        <center>
          <v-btn @click="showLoopDeletionDialog = false" elevation="0">
            {{ $t("no") }}
          </v-btn>
          <v-btn color="error" :loading="deleteLoopIsLoading" @click="deleteLoop(searchIdToDelete)">{{ $t("yes") }}</v-btn>
        </center>
      </v-card>
    </v-dialog>

    <DuplicateLoopDialog :show.sync="showLoopDuplicationDialog" :searchIdToDuplicate="searchIdToDuplicate" />

    <ExportJobsDialog ref="exportJobsDialog" />
  </v-row>
</template>
<script>
import moment from "moment";
import DuplicateLoopDialog from "@/components/DuplicateLoopDialog.vue";
import LoopListItemPresentation from "@/components/LoopListItemPresentation.vue";
import appSDK from "@/client/AppSDK";
import ExportJobsDialog from "@/components/ExportJobsDialog.vue";
import { mapGetters } from "vuex";
import { constant } from "@/store/constant";

export default {
  components: {
    DuplicateLoopDialog,
    LoopListItemPresentation,
    ExportJobsDialog,
  },

  data() {
    return {
      searchIdToDelete: "",

      loops: [],

      isLoading: true,
      color: "#F0FFFF",
      deleteLoopIsLoading: false,

      showLoopDeletionDialog: false,
      enableEmails: true,

      searchIdToDuplicate: null,
      showLoopDuplicationDialog: false,

      lastScanned: null,
    };
  },

  computed: {
    ...mapGetters("user", { getUser: "getUser" }),

    user() {
      return this.getUser;
    },
    lastScannedText() {
      if (!this.lastScanned) return null;

      moment.locale(this.$i18n.locale);
      return moment(this.lastScanned).format("LLL") + " (" + moment(this.lastScanned).fromNow() + ")";
    },
    areThereLoops() {
      return this.loops && this.loops.length;
    },
    withoutApplications() {
      return !this.$store.getters.configs?.disableApplications;
    },
  },

  async mounted() {
    try {
      const dashboardUser = await this.$store.dispatch(constant.user.actions.GetDashboardUser);
      this.lastScanned = dashboardUser.lastScanned;
      this.fetchUserData();
    } catch (error) {
        this.$notify({
          message: error.message || this.$t("errors.unexpected"),
          type: "danger",
      });
    }
  },

  methods: {
    async fetchUserData() {
      this.enableEmails = this.user.enableEmailsApplications;
      this.getUserLoops();
    },
    handleLoopClick() {
      this.$amplitude.logEvent("OPEN_LOOP_DETAILS");
    },

    getUserLoops() {
      appSDK["loop.list"]()
        .then(res => {
          this.loops = res;
          this.$amplitude.identify(this.user.userId, {
            totalJobs: this.loops
              .map(obj => obj.totalJobs)
              .filter(v => v !== undefined)
              .reduce((a, b) => a + b, 0),
            totalMatches: this.loops
              .map(obj => obj.totalMatches)
              .filter(v => v !== undefined)
              .reduce((a, b) => a + b, 0),
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    promptToDeleteLoop(searchId) {
      this.searchIdToDelete = searchId;
      this.showLoopDeletionDialog = true;
    },

    promptToDuplicateLoop(searchId) {
      this.searchIdToDuplicate = searchId;
      this.showLoopDuplicationDialog = true;
    },

    deleteLoop(index) {
      this.deleteLoopIsLoading = true;
      this.$amplitude.logEvent("DELETE_LOOP");
      appSDK["loop.delete"](index)
        .then(response => {
          console.log("deleted loop", response);
          
          const zeroIndex = this.loops.findIndex(loop => loop.searchId === index);
          this.loops.splice(zeroIndex, 1); // TODO reactive
          this.isLoading = false;
          this.showLoopDeletionDialog = false;
          this.$store.dispatch(constant.user.actions.UpdateLoopCount, -1);
        })
        .catch(error => {
          console.log(error);
          this.$notify({
            message: error.message || this.$t("errors.unexpected"),
            type: "danger",
          });
        }).finally(()=>{
          this.deleteLoopIsLoading = false;
        });
    },

    async changeEmails() {
      this.$amplitude.logEvent("ENABLE_EMAILS:" + this.enableEmails);

      await appSDK["user.updateInfo"]({
        enableEmailsApplications: this.enableEmails,
      });
    },

    newloop() {
      this.$amplitude.logEvent("CREATE_NEW_LOOP_AUTOMATIONS_BUTTON");
      this.$router.push("/loops/create");
    },

    editLoop(searchId) {
      this.$amplitude.logEvent("EDIT_LOOP");
      this.$router.push(`/loops/${searchId}/edit`);
    },
  },
};
</script>

<style scoped>
.lft {
  text-align: left;
}
.loader {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10em;
}
.is-auto {
  border: 1px solid #4caf50;
}
</style>
<style>
@import "../../assets/css/tooltip.css";

.modalAutomationsDialog {
  margin-top: 5em;
  /* margin-left: 15em; */
  /* width: 86em !important; */
  max-width: 100%;
}

.moreImageStyle {
  width: 20px;
  height: 20px;
  padding-right: 0.3em;
}
.lastTime {
  /* border: 1px black solid; */
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  padding-left: 0.4em;
  padding-right: 0.4em;
  margin: 0.1em 0.4em;
  background-color: #d3d8db;
  border-radius: 4px;

  /* font-family: helvetica,Arial,sans-serif */
}
.lastTime:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}
.DeleteBut {
  background: #e34a17;
}
</style>
<style lang="scss">
$display4-size: 1.6275rem !default;
$font-weight-bold: 600 !default;
$display4-weight: $font-weight-bold !default;
$headings-line-height: 1.2 !default;
$display-line-height: $headings-line-height !default;
.job-title-header {
  font-size: $display4-size;
  font-weight: $display4-weight;
  line-height: $display-line-height;
}

.loops-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr));
  max-width: 1200px;
  margin: auto;
  width: 100%;
  justify-content: space-around;
}

@media screen and (max-width: 600px) {
  .loops-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  .loop-block-parent {
    padding-left: 0;
    padding-right: 0;
  }
}
</style>
